// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCwh6I9PDMKZY6BQ4K7R8ajEFbUcJAQzNI",
//   authDomain: "coolpam-water-system.firebaseapp.com",
//   projectId: "coolpam-water-system",
//   storageBucket: "coolpam-water-system.appspot.com",
//   messagingSenderId: "871969551047",
//   appId: "1:871969551047:web:312a34dac690ee10c844e2",
//   measurementId: "G-VTBRYXQT8N"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCcY37Gq86pFq2zqWXAK79ZRtrKRqUHRMw",
  authDomain: "coolpam-97625.firebaseapp.com",
  projectId: "coolpam-97625",
  storageBucket: "coolpam-97625.appspot.com",
  messagingSenderId: "735885876465",
  appId: "1:735885876465:web:5f20defe935501c0b75d50",
  measurementId: "G-59ZYMJZ2V7"
};

// Initialize Firebase
export const FirebaseCore = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}