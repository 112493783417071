import { FunctionComponent } from "react";
import '../Assets/css/loader.css';

interface LoaderProps {

}

const Loader: FunctionComponent<LoaderProps> = () => {
    return (<div className="lds-ring"><div></div><div></div><div></div><div></div></div>);
}

export default Loader;