import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { useEffect, useState, lazy, Suspense } from 'react';
import { getAuth } from '@firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ToastContainer } from 'react-toastify';
import { FirebaseCore } from './Firebase/FirebaseCore';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Pages/Loader';

const SignIn = lazy(() => import('./Pages/SignIn'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const Payments = lazy(() => import('./Pages/Payments'));
const ProtectedDashboard = lazy(() => import('./ProtectedRoutes/ProtectedDashboard'));
const ProtectedSignin = lazy(() => import('./ProtectedRoutes/ProtectedSignin'));
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword'));
const Error404 = lazy(() => import('./Pages/404'));
const Error403 = lazy(() => import('./Pages/403'));
const Customers = lazy(() => import('./Pages/Customers'));
const Metering = lazy(() => import('./Pages/Metering'));
const Staff = lazy(() => import('./Pages/Staff'));
const Users = lazy(() => import('./Pages/User'));
const StaffSpecific = lazy(() => import('./Pages/StaffSpecific'));
const Billing = lazy(() => import('./Pages/Billing'));
const Sites = lazy(() => import('./Pages/Sites'));
const MainPump = lazy(() => import('./Pages/MainPump'));
const BowserPump = lazy(() => import('./Pages/BowserPump'));
const Permissions = lazy(() => import('./Pages/Permissions'));

function App() {
  FirebaseCore();
  const auth = getAuth();
  const [user] = useAuthState(auth);
  return (
    <>
      <Suspense fallback={<Loader />}>
        <ToastContainer />
        <Router>
          <Switch>
            <ProtectedSignin exact path="/" component={SignIn} />
            <ProtectedDashboard exact path="/dashboard" component={Dashboard} />
            <ProtectedDashboard exact path="/staff" component={Staff} />
            <ProtectedDashboard exact path="/customers" component={Customers} />
            <ProtectedDashboard exact path="/metering" component={Metering} />
            <ProtectedDashboard exact path="/customers-specific/:useremail" component={Users} />
            <ProtectedDashboard exact path="/staff-specific/:useremail" component={StaffSpecific} />
            <ProtectedDashboard exact path="/billing" component={Billing} />
            <ProtectedDashboard exact path="/sites" component={Sites} />
            <ProtectedDashboard exact path="/payments" component={Payments} />
            <ProtectedDashboard exact path="/main-pump" component={MainPump} />
            <ProtectedDashboard exact path="/bowsers" component={BowserPump} />
            <ProtectedDashboard exact path="/permissions" component={Permissions} />
            <Route path="/forgot-password"><ForgotPassword /></Route>
            <Route path="/forbidden"><Error403 /></Route>
            <Route path="*"><Error404 /></Route>
          </Switch>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
